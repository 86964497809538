<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <h2 style="color: #0082d5" class="font-bold">
        {{ $t("AddAssignDoctorsToSpecialties") }}
      </h2>
    </div>

    <div class="mt-5">
      <div style="font-weight: bold">{{ $t("ChooseMainSpecialty") }}</div>

      <div class="flex flex-col" v-if="surgeryCategories.length > 0">
        <div class="flex overflow-x-scroll py-5 items hide-scroll-bar">
          <!-- <div
            class="inline-block px-1"
            v-on:click="selectSurgCategory(null, null)"
          >
            <div
              style="background-color: #355a85; height: 60px"
              :class="[{ 'bg-primary': ActiveIndex == null }]"
              class="RoundedCurve w-48 max-w-xs pb-5 overflow-hidden"
            >
              <h4 class="mx-auto text-center mt-5 justify-center text-white">
                {{ $t("All") }}
              </h4>
            </div>
          </div> -->
          <div
            class="flex flex-nowrap lg:ml-40 md:ml-12 ml-6"
            v-for="(item, index) in surgeryCategories"
            :key="index.ID"
          >
            <div
              class="inline-block"
              v-on:click="
                selectSurgCategory(item, index);
                select(item);
              "
            >
              <div
                :class="[{ 'bg-primary': ActiveIndex == index }]"
                style="background-color: #355a85; height: 60px"
                class="RoundedCurve w-48 pb-2 max-w-xs overflow-hidden center"
              >
                <h4
                  class="mx-auto text-center mt-2 justify-center text-white p-1"
                  style="white-space: inherit"
                >
                  {{ item.Specialty.Name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="font-weight: bold">{{ $t("ChooseSubSpecialty") }}</div>

      <div class="flex flex-col m-auto" v-if="surgeriesByCat.length > 0">
        <div class="flex overflow-x-scroll py-5 hide-scroll-bar">
          <div
            class="flex lg:ml-40 md:ml-12 ml-6"
            v-for="(item, index) in surgeriesByCat"
            :key="index.ID"
          >
            <div
              class="px-1"
              v-on:click="
                select(item);
                MakeActive(index);
              ">
              <div
                :class="[{ 'bg-primary': SecondActiveIndex == index }]"
                style="background-color: #355a85;width:18rem;height:60px;line-height: 200px;text-center"
                class="RoundedCurve pb-2 max-w-xs overflow-hidden center"
              >
                <h4
                  class="textFont mt-2 text-white p-1"
                  style="white-space: inherit; vertical-align: baseline"
                >
                  {{ item.Specialty.Name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <h2>{{ $t("AddDoctors") }}</h2>
      <div class="vx-row">
        <div class="vx-col lg:w-1/5 w-full"></div>
        <div class="vx-col lg:w-1/3 w-full" id="custumcard">
          <vs-card class="vx-col" style="height: 50vh; overflow-y: scroll">
            <h3 class="ActiveCardHeader primaryBackColor">
              {{ $t("Doctors") }}
            </h3>
            <div
              class="Active mt-3 ml-5 vx-row"
              v-if="searchDoctorModel.SpecialtyID > 0"
            >
              <feather-icon
                style="width: 15px"
                icon="PlusCircleIcon"
                class="cursor-pointer"
              ></feather-icon>
              <u @click="ShowCreateDoctorProfile = true" class="mt-1 ml-2">{{
                $t("CreateNewDoctorProfile")
              }}</u>
            </div>
            <div class="m-5">
              <vs-input
                v-model="search.DoctorName"
                :placeholder="$t('Search')"
                class="w-full"
                name="search"
              />
              <div
                v-for="item in filterDoctor"
                :key="item.ID"
                :class="item.IsSelected ? 'Active' : 'InActive'"
                class="shadow-md w-full mt-2"
              >
                <p class="m-5 p-3 vx-row">
                  <vs-checkbox
                    v-model="item.IsSelected"
                    @input="DoctorChecked(item)"
                    :disabled="
                      !(searchDoctorModel.SpecialtyID > 0) ||
                      item.CantChecked == true
                    "
                  ></vs-checkbox>
                    <span v-if="item.FullName">  {{ item.FullName }} </span>
                 <span v-else>  {{ item.Name }} </span>
                </p>
              </div>
            </div>
          </vs-card>
        </div>

        <div class="vx-col lg:w-1/3 w-full">
          <vs-card class="vx-col" style="height: 50vh; overflow-y: scroll">
            <h2 class="Active">{{ $t("AllAddedDoctors") }}</h2>

            <div
              v-for="item in HospitalDoctors"
              :key="item.ID"
              class="InActive shadow-md w-full mt-2"
              v-if="item.IsActive != false"
            >
              <li style="margin: 5%; padding: 3%; font-size: medium">
              
                  <span v-if="item.Doctor">  {{ item.Doctor.FullName }} / {{item.HospitalSpecialty.Specialty.Name}} </span>
                 <span v-else>  {{ item.Doctor.Name }} </span>
              </li>
            </div>
          </vs-card>
          <div class="w-full mb-10">
            <div style="text-align: end">
              <vs-button
                color="#004477"
                @click="SaveDoctors()"
                class="customizer-btn text-sm text-white"
                ><h6 style="color: white">{{ $t("Submit") }}</h6>
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <vs-popup fullscreen title="" :active.sync="ShowCreateDoctorProfile">
        <CreateDoctorProfile
          v-if="ShowCreateDoctorProfile"
          :searchDoctorModel="searchDoctorModel"
          @closePop="ShowCreateDoctorProfile = false"
        ></CreateDoctorProfile>
      </vs-popup>
    </div>
  </div>
</template>

<script>
//import LoginFirebase from "./LoginFirebase.vue"
//import LoginAuth0 from "./LoginAuth0.vue"
import axios from "@/axios.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import CreateDoctorProfile from "@/views/hospital/CreateDoctorProfile.vue";

import { domain, mainSetting } from "@/gloabelConstant.js";
export default {
  data() {
    return {
      EnglishLan: false,
      isAll: false,
      ShowCreateDoctorProfile: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      Model: {},
      search: {},
      searchDoctorModel: {
        
      },
      selectedSpecialityFilter: {},
      HospitalDoctors: [],
      SecondActiveIndex: null,
      ActiveIndex: null,
      MainsurgeriesByCat: [],
      surgeriesByCat: [],
      surgeryCategories: [],
    };
  },
  components: {
    CreateDoctorProfile,
  },
  computed: {
    searchDoctors() {
      return this.$store.state.DoctorList.searchDoctors;
    },
    filterDoctor() {
      if (this.isAll == true) return null;
      debugger;
      if (this.search.DoctorName) {
        var vm = this;
        return this.searchDoctors.filter(function (obj) {
          return (
            obj.Name.toLowerCase().indexOf(vm.search.DoctorName.toLowerCase()) >
            -1
          );
        });
      } else return this.searchDoctors;
    },
  },
  methods: {
    MakeActive(index) {
      this.SecondActiveIndex = index;
    },
    selectSurgCategory(data, index) {
      debugger;
      this.ActiveIndex = index;
      this.SecondActiveIndex = null;
      if (data != null) {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID == data.Specialty.ID
        );
        this.isAll = false;
      } else {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
        this.isAll = true;
      }
    },

    searchDoctor() {
      this.searchDoctorModel.SmallSearch = true;
      this.$store
        .dispatch("DoctorList/SearchDoctors", this.searchDoctorModel)
        .then(() => {
          debugger;

          this.HospitalDoctors.forEach((element) => {
            debugger;
            var index = this.searchDoctors.findIndex(
              (x) => x.ID == element.DoctorID  && element.HospitalSpecialtyID==this.searchDoctorModel.HospitalSpecialityID
            );
            if (index != -1) {
              this.searchDoctors[index].IsSelected = true;
            }

            // var index2 = this.searchDoctors.findIndex(
            //   (x) => x.ID == element.DoctorID 
            // );
            // if (index2 != -1) {
            //   this.searchDoctors[index2].CantChecked = true;
            // }

            // this.$set(this.searchDoctors[index], 'IsSelected', true)
          });
        });
    },
    select(item) {
      debugger;
      this.selectedSpecialityFilter = item;
      this.searchDoctorModel.SpecialtyID = item.SpecialtyID;
      this.searchDoctorModel.HospitalSpecialityID = item.ID;
      this.selectedChiledSpecialityFilter = {};
      this.isAll = false;
      this.searchDoctor();
    },
    searchHospitalSpecialty() {
      debugger;
      this.$store
        .dispatch("HospitalList/SearchHospitalSpecialtys", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
        })
        .then((res) => {
          this.surgeryCategories = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID == null
          );
          this.MainsurgeriesByCat = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID != null
          );
          this.surgeriesByCat = this.MainsurgeriesByCat;
        });
    },

    back() {
      this.$router.go(-1);
    },
    DoctorChecked(item) {
      debugger;
      if (item.IsSelected == true) {
        this.AddHospitalDoctor(item);
      } else {
        this.DeleteHospitalDoctor(item);
      }
    },
    DeleteHospitalDoctor(item) {
      debugger
      var index = this.HospitalDoctors.findIndex((x) => x.DoctorID == item.ID&&x.HospitalSpecialtyID==this.searchDoctorModel.HospitalSpecialityID);
      this.HospitalDoctors.splice(index, 1);
    },
    AddHospitalDoctor(item) {
      var index = this.HospitalDoctors.findIndex((x) => x.DoctorID == item.ID);
      if (index >= 0) {
        //alert
      } else {
        var hospitalDoctor = {};
        hospitalDoctor.Doctor = {};
        hospitalDoctor.Doctor.Name = item.Name;
        hospitalDoctor.Doctor.ID = item.ID;
        hospitalDoctor.DoctorID = item.ID;
        hospitalDoctor.IsActive = true;
        hospitalDoctor.HospitalSpecialtyID =
          this.searchDoctorModel.HospitalSpecialityID;
        hospitalDoctor.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        this.HospitalDoctors.push(hospitalDoctor);
      }
    },
    SaveDoctors() {
      this.$vs.loading();

      debugger;
      if (this.HospitalDoctors.length > 0) {
        this.$store
          .dispatch("HospitalList/SaveHospitalDoctors", this.HospitalDoctors)
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              //  this.back();
            }
            this.$vs.loading.close();
          });
      } else {
        this.$store
          .dispatch(
            "HospitalList/DeleteOldHospitalDoctors",
            this.$store.state.AppActiveUser.Hospital.ID
          )
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              //  this.back();
            }
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$vs.loading();
 
    this.searchHospitalSpecialty();
    this.searchDoctorModel.CountryID = this.$store.state.AppActiveUser.Hospital.CountryID;
    debugger;
    this.$store
      .dispatch(
        "HospitalList/GetHospitalDoctors",
        this.$store.state.AppActiveUser.Hospital.ID
      )
      .then((res) => {
        this.HospitalDoctors = res.data.Data;
        this.$vs.loading.close();
      });
  },
};
</script>

<style >
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textFont {
  font-size: 16px !important;
  white-space: nowrap;
  text-align: center !important;
}
#custumcard .vs-card--content {
  padding: unset;
}
#customRow .vx-col {
  padding: unset;
}
.Active {
  color: #004477;
  padding: 0px;
  border-radius: 12px;
}
.InActive {
  padding: 0px;
  border-radius: 12px;
}
p {
  font-size: medium;
}
</style>
<style scoped>
.RoundedCurve {
  border-radius: 20px;
  background: #355a85;
}
</style>